/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;1,100&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
body {
  font-family: "Poppins", sans-serif !important;
}
.generic-heading-level-2 {
  font-weight: 600;
}
.h5,
h5 {
  font-weight: 600 !important;
}

.btn-header-disable {
  background: gray;
  border-radius: 4px;
  border: none;
  /* color: white; */
  /* font-size: 18px; */
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.btn-header {
  background: linear-gradient(0deg, #39b6fe, #39b6fe), #ffffff;
  border-radius: 4px;
  border: none;
  /* color: white; */
  /* font-size: 18px; */
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.header-icon {
  width: 50%;
}

  .user-menu .badge {
    background-color: #f26522 !important;
    top: -8px !important;
    left: -9px !important;
}

.notification-icon {
  position: absolute;
  cursor: pointer;
  right: 16.1%;
  top: 4.9px;
  font-size: 6px !important;
}
.label-primary {
  font-weight: 500 !important;
  font-size: 13.4687px !important;
}
input::placeholder {
  font-weight: 400 !important;
  font-size: 12.4687px !important;
  color: #9ca3af !important;
}
select {
  font-weight: 400 !important;
  font-size: 12.4687px !important;
  /* color: #9ca3af !important; */
}
.breadcrum_Project a {
  text-decoration: none !important;
  color: #39b6fe !important;
  font-size: 16px !important;
}
.breadcrumb-item::before {
  display: none;
}
.breadcrum_addProject {
  color: #818181 !important;
  /* font-size: 14px !important; */
}
.header-breadcrumb ol {
  margin-bottom: 0px;
}
.btn-closeAndBack {
  border: 0 !important;
  background-color: transparent !important;
  font-weight: 600;
  font-size: 15.4332px;
  color: #000000;
}
.btn-primary {
  background: #0f71b0 !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 4px 8px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #ffffff !important;
}
.btn-draft {
  border: var(--bs-border-width) var(--bs-border-style) #0971ad !important;
  /* border: 0 !important; */
  background-color: transparent !important;
  font-weight: 600;
  font-size: 14px;
  color: #0971ad;
  padding: 4px 8px !important;
  border-radius: 4px !important;
}
.image-upload {
  border: var(--bs-border-width) var(--bs-border-style) #b7b7b7 !important;
  /* border: 0 !important; */
  background-color: #e8e8e8 !important;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  padding: 6px 18px !important;
  border-radius: 2px !important;
}
.card-internal-heading {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.card-internal-text {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.secondary-heading {
  font-size: 17px !important;
  font-weight: 600 !important;
}
/* .table thead {
} */
.user-table .table thead tr th {
  font-size: 14px !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.user-table .table tbody tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
  /* font-family: "Poppins"; */
}
.blog-table .table thead tr th {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
}
.blog-table .table tbody tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
  /* font-family: "Poppins"; */
}
.recent-blogs .table thead tr th {
  font-size: 14px !important;
  font-weight: 600 !important;
  /* text-align: center !important; */
}
.recent-blogs .table tbody tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  /* text-align: center !important; */
  /* font-family: "Poppins"; */
}
.modal-delete-icon-styling {
  font-size: 50px !important;
  /* height: 58px; */
  background: #ff5555 !important;
  /* width: 58px; */
  padding: 13px;
  border-radius: 50%;
  color: #ffffff !important;
}
.modal-archive-icon-styling {
  font-size: 50px !important;
  /* height: 58px; */
  background: #39b6fe !important;
  /* width: 58px; */
  padding: 13px;
  border-radius: 50%;
  color: #ffffff !important;
}
.modal-request-sent-icon-styling {
  font-size: 50px !important;
  background: #71d76f !important;
  padding: 13px;
  border-radius: 50%;
  color: #ffffff !important;
}
.modal-request-icon-styling {
  font-size: 70px !important;
  padding: 13px;
  fill: #39b6fe;
}
.dropdown .dropdown-menu .delete-btn {
  background-color: #f22222;
}
.highcharts-grid-line {
  display: none !important;
}

.login-component,
.signup-component,
.forget-component {
  height: 100vh;
}
.login-component .bg-img,
.signup-component .bg-img,
.forget-component .bg-img {
  background-image: url("../images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position-x: right;
}
.login-component .login-page {
  margin: auto;
  width: 68%;
  height: 100vh;
  /* vertical-align: middle !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-component .login-page img {
  width: 250px;
  /* margin-bottom: 60px; */
}
.login-component .heading1,
.signup-component .heading1,
.forget-component .heading1 {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 40px;
  color: #ffffff;
}
.login-component .heading2,
.signup-component .heading2,
.forget-component .heading2 {
  font-weight: 500;
  font-size: 1vw;
  color: #ffffff;
}
.login-component .login-content {
  width: 60%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.login-heading {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 10px;
}
.eye-icon {
  position: absolute;
  right: 4px;
  bottom: 4px;
}
.error-message-field-generic {
  color: #f22222;
  font-size: 12px;
}
.print-data {
  color: #0971ad !important;
}
.print-data .Toastify__progress-bar-theme--light {
  background: #0971ad !important;
}
.print-data .Toastify__toast-icon svg {
  fill: #0971ad !important;
}
.notification-img {
  width: 35px;
  background-color: #f4f6f9;
  height: 35px;
  border-radius: 50px;
  display: flex !important;
  margin-right: 0.5rem !important;
}
.notify-name {
  width: 100% !important;
  text-align: center !important;
  align-self: center !important;
  font-weight: 700;
}
.accept-btn {
  background: #71d76f !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 2px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.reject-btn {
  background: #39b6fe !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 2px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
}
.dropdown.show:before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(100, 100, 100, 0.5);
  content: "";
  z-index: 1;
}
.sidebar-log-img {
  max-width: 100%;
  height: auto;
  height: 150px;
  width: 170px;
}
.blogs-icons {
  width: 20px;
  height: 20px;
  margin: 0 3px;
}
.tooltip-reason {
  background-color: #fff;
  box-shadow: 0 0 7px #ccc;
  padding: 1px 23px;
  border-radius: 7px;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 14px;
  display: none;
}
.tooltip-box:hover .tooltip-reason {
  display: block;
}
.cursor-pointer {
  cursor: pointer !important;
}
.reject-text {
  background-color: #dd3434;
  color: #fff;
  padding: 3px 11px;
  border-radius: 4px;
}
.pending-text {
  background-color: #f2c261;
  color: #fff;
  padding: 3px 13px;
  border-radius: 4px;
}
.approved-text {
  background-color: #8bc34a;
  color: #fff;
  padding: 3px 8px;
  border-radius: 4px;
}
.delivered-text {
  background-color: #0971ad;
  color: #fff;
  padding: 3px 8px;
  border-radius: 4px;
}
.pagination {
  margin: 0;
}
.blog-detail {
  overflow-y: scroll;
  text-overflow: ellipsis;
  white-space: break-spaces;
  height: 218px !important;
}
.breadcrumb {
  font-weight: bold;
  color: #000;
}
.notification-unread{
  font-size: 12px;
  font-weight: 700;
  background-color: #39b6fe;
  color: white;
  padding: 3px 8px;
  border-radius: 3px;
}
.breadcrumb-item.active {
  color: #000 !important;
}
.role-setting {
  position: absolute;
  top: 25px;
  left: 50px;
  font-size: 13px;
  font-weight: 500 !important;
}
.user-modal .btn-close {
  position: absolute;
  right: 15px;
  top: 18px;
  border: 1px solid #ccc;
  border-radius: 5px !important;
}
.dashboard-date-menu {
  width: 200px;
}
.date-filter-dropdown {
  background-color: unset !important;
  border: unset !important;
}
.cursor-unset {
  cursor: auto !important;
}
.not-deleted {
  margin-right: 16px;
}
.username-heading {
  text-transform: uppercase;
  background-color: #0971ad;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 20px;
}
.cant-delete {
  left: 42% !important;
}
.title-input-style {
  border: 1px solid #ccc;
  padding: 10px 10px;
  width: 540px;
  border-radius: 6px;
}

.tooltip-box2 {
  background-color: #0971ad;
  color: #fff;
  font-weight: 600;
  font-size: 90%;
  padding: 10px 11px;
  border-radius: 3px;
  margin: 6px 0 0 0;
  position: absolute;
  right: 0;
  top: -7px;
  width: 200px;
  display: none;
  left: 62px;
}
.mini-sidebar .sidebar-menu > ul > li:hover .tooltip-box2 {
  display: block;
}
.dropdown.dashboard-menu.show:before {
  background: unset !important;
}

.dashboard-date-menu {
  position: absolute;
  right: 0;
  z-index: 99;
  background-color: #fff;
  padding: 13px 10px !important;
  top: 25px;
  border: 1px solid #d9d6d6;
  border-radius: 6px;
}
.dashboard-menu {
  position: relative;
}
.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 0;
  background-color: #fff;
  /* z-index: 9; */
}
.blog-three-dot {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 373px;
  /* font-weight: 600; */
  cursor: pointer;
  display: inline-block;
  margin: 0 auto !important;
}
