.text-style {
  font-weight: 600;
  color: black;
}
.text-style:hover {
  background-color: #f0f0f0;
}
.text-style-default {
  background-color: #0971ad !important;
  font-weight: 600;
  color: white !important;
}
/* .text-link{
  color: #0971ad;
} */
.react-bootstrap-table-pagination-list {
  align-items: center;
  display: flex;
  justify-content: right;
  border: none !important;
  padding: 0 !important;
  /* background-color: #0971ad !important; */
}
.user-table .table-bordered th,
.user-table .table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
  border: none !important;
}
.user-table .table tbody tr {
  border: none !important;
}
.user-table .table tbody tr:hover {
  cursor: pointer;
}
.blog-table .table-bordered th,
.blog-table .table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
  border: none !important;
}
.blog-table .table tbody tr {
  border: none !important;
  vertical-align: middle;
}
.recent-blogs .table-bordered th,
.recent-blogs .table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
  border: none !important;
}
.recent-blogs .table tbody tr {
  border: none !important;
}
.recent-blogs .table thead tr {
  border-style: hidden !important;
  border: none !important;
  cursor: pointer;
}
.user-table .table thead {
  background-color: #0971ad !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  color: white !important;
  border: none !important;
  cursor: pointer;
}
.total-user-component {
  border-bottom: 1px solid #ebebeb;
}
.blog-table .table thead {
  background-color: #0971ad !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  color: white !important;
  border: none !important;
  cursor: pointer;
}
.user-table .table {
  border: none !important;
}
.total-users .highcharts-point:hover {
  fill: #badaff !important;
}
.total-blogs .highcharts-point:hover {
  fill: #8cd7ff !important;
}
.filter-btn {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  background-color: #0971ad;
  padding: 4px 8px;
  border-radius: 3px;
}
.bg-cutom-color {
  background-color: #39b6fe;
}
.status-style {
  background-color: #71d76f;
  color: white;
  border-radius: 4px;
  padding: 2px 0px 2px 0px;
}
.inactive-status-style {
  background-color: #f94242;
  color: white;
  border-radius: 4px;
  padding: 2px 0px 2px 0px;
}
.delete-icon {
  color: #f22222;
  font-size: 18px;
  cursor: pointer;
}
.edit-icon {
  color: #ababab;
  font-size: 16px;
  cursor: pointer;
}
.download-icon {
  color: #39b6fe;
  font-size: 16px;
  cursor: pointer;
}
.user-modal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #f4f6f9;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.btn-secondry {
  background: #0f71b0 !important;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 18px;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.view-btn {
  background: #0f71b0 !important;
  border-radius: 4px;
  border: none;
  color: white;
  font-size: 18px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}
.user-modal .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center !important;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.user-modal .modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: 0px !important;
  margin-left: 100px !important;
  /* border: 1px solid #AAAAAA; */
  border-radius: 0px;
  font-size: 10px;
}
.cursor {
  cursor: pointer;
}
.user-modal .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center !important;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.upload-image {
  background-color: #e8e8e8;
}
.doted-border {
  background-color: #e8e8e8;
  border: 1px dashed #b7b7b7;
}
.bg-light-info {
  /* background-color: #F4F6F9 !important; */
  background-color: #f2f5f8 !important;
}
.card-img-div-styling {
  min-height: 160px;
  background-color: #d3d3d3;
}
.blog-image-style {
  margin-top: 1.6rem !important;
  cursor: pointer;
}
.generic-text-muted {
  color: #a4a4a4;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.generic-color {
  color: #0971ad;
}
.edit-icon-rounded {
  background-color: #d9d9d9;
  padding: 5px;
  font-size: 22px;
  border-radius: 50%;
  color: white;
}
.light-shadow {
  box-shadow: 0px 2px 24px -12px #888888;
}
.user-profile > img {
  height: 55px;
  object-fit: cover;
  width: 55px;
}
.blog-user-profile > img {
  height: 45px;
  object-fit: cover;
  width: 45px;
}
.user-profile .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none !important;
}
.user-profile .dropdown-toggle {
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
}
.set-loader-margin{
  margin-top: 260px !important;
}
.user-profile .dropdown-item {
  display: block;
  font-size: 10px;
  /* padding: 5px 21px 5px 5px !important; */
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #0971ad;
  border-radius: 5px;
}
.user-profile .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #ffffff;
  min-width: 100%;
  margin-left: 0.5rem !important;
  margin-top: -0.5rem !important;
  border-radius: 5px;
}
.user-notify .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none !important;
}
.user-notify .dropdown-toggle {
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
}
.user-notify .dropdown-item {
  display: block;
  font-size: 10px;
  /* padding: 5px 21px 5px 5px !important; */
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  /* background-color: transparent; */
  /* border: 1px solid #0971ad; */
  border-radius: 5px;
}
.user-notify .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #ffffff;
  min-width: 100%;
  margin-left: -12.5rem !important;
  margin-top: 0.25rem !important;
  border-radius: 5px;
}
.count-user {
  width: 40px;
  height: 40px;
  color: #39b6fe;
  font-size: 25px;
  text-align: center;
}
.user-listing-loader .cutom-loader-style {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  border-width: 3px !important;
  border-style: solid;
  border-color: rgb(15, 113, 176) rgb(15, 113, 176) transparent;
  border-image: initial;
  display: inline-block;
  animation: 0.75s linear 0s infinite normal both running
    react-spinners-ClipLoader-clip;
}
